<template>
  <div>
    <ExtendedView
      :tabs="tabs"
      :selected-object="selectedTemplate"
      @clear-selected-object="selectedTemplate = null"
    >
      <template
        v-slot:list="{ item }"
      >
        <ActionList
          :key="item.key"
          :filters="[]"
          :load-items-callback="getCustomTemplates"
          :call-to-actions="callToActions"
        >
          <template v-slot:item="{ item, index }">
            <TemplateListItem
              :template="item"
              :key="item.uuid"
              @selectTemplate="setSelectedTemplate"
              @updateSelectedTemplate="updateSelectedTemplate"
            />
            <v-divider
              inset
              :key="index"
            />
          </template>
        </ActionList>
      </template>
      <template slot="profile">
        <TemplateProfile
          v-if="selectedTemplate"
          :template="selectedTemplate"
        />
      </template>
    </ExtendedView>
  </div>
</template>

<script>
export default {
	name: 'Templates',
	components: {
		'ExtendedView': () => import('@/components/common/ExtendedView'),
		'ActionList': () => import('@/components/common/list/ActionList'),
		'TemplateListItem': () => import('@/components/template/TemplateListItem'),
    'TemplateProfile': () => import('@/components/template/TemplateProfile')
	},
	data() {
		return {
			selectedTemplate: null,
			tabs: [
				{
					title: this.$t('all'),
					key: 'all'
				}
			],
			callToActions: [
				{
					title: this.$t('addTemplate'),
					callback: this.showTemplateDialog
				}
			]
		}
	},
	methods: {
		getCustomTemplates({limit, skip, conditions}) {
			return this.$store.dispatch('getCustomTemplates', {limit: limit, conditions})
		},
    setSelectedTemplate(template) {
      this.selectedTemplate = template
    },
		updateSelectedTemplate(template) {
			if(template.uuid === this.selectedTemplate.uuid) {
				this.selectedTemplate = template
			}
		},
		showTemplateDialog(){
			//Add Template
			this.$store.commit('updateDataToMutate', {
				title: this.$t('createCustomTemplate'),
				objectToMutate: {},
				formComponent: 'components/template/TemplateForm',
				saveCallback: function(newCustomTemplate) {
					this.$store.dispatch('createCustomTemplate', newCustomTemplate).then(res => {
						if(res) {
							this.$store.commit('updateActionSuccess', {
								message: this.$t('success'),
								subMessage: this.$t('successfullyCreatedTemplate')
							})
						} else {
							this.$store.commit('updateActionError', {
								message: this.$t('error'),
								subMessage: this.$t('couldNotCreateTemplate')
							})
						}
					}).catch(() => {
						this.$store.commit('updateActionError', {
							message: this.$t('error'),
							subMessage: this.$t('couldNotCreateTemplate')
						})
					})
				}
			})
		}
	},
	mounted() {
      this.$store.commit('updateAppBarTabs', this.tabs)
      this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
	  },
}
</script>
